mat-form-field{
  width: 100%;
  .mat-form-field {
    &-underline {
      position: relative;
      bottom: auto;
    }
    &-subscript-wrapper {
      position: static;
    }
  }
}
.mat-form-field-appearance-outline{
  .mat-form-field-suffix{
    align-self: center;
  }
}

.cdk-overlay-container{
  z-index: 2000 !important;
}