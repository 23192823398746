via-map div[style*="font-family: montserrat"] {
    opacity: 1 !important;
    position: absolute;
    overflow: hidden;
    margin-left: -32px;
    margin-top: 20px !important;
    z-index: -1;
    display: block;
    color: rgb(0, 0, 0) !important;
    font-size: 14px;
    font-family: Open-sans, sans-serif;
    background: #ffffff;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}

.win-page-title {
    font-size: 22px;
    font-weight: bold;
    margin-top: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.win-page-text {
    font-size: 16px;
    color: #999999;
}

.z-depth-1 {
    box-shadow: $z-depth-1;
}

.m-10 {
    margin: 10px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.danger-text {
    color: $warn-color;
}

.infor-text {
    color: $success-green !important;
}

.center-text {
    text-align: center;
}

.uppercase-text {
    text-transform: uppercase;
}

.bold-text {
    font-weight: bold;
}

.right-align-text {
    text-align: right;
}

.display-full-row {
    display: block;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-b-0 {
    margin-bottom: 0;
}

.m-l-50 {
    margin-left: 50px;
}

.m-t-50 {
    margin-top: 50px;
}

.m-t-100 {
    margin-top: 100px;
}

.m-b-50 {
    margin-bottom: 50px;
}

.m-b-0 {
    margin-bottom: 0px;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-r-50 {
    margin-right: 50px;
}

.m-r-20 {
    margin-right: 20px;
}

.p-10 {
    padding: 10px !important;
}

.p-r-15 {
    padding-right: 15px;
}

.p-l-0 {
    padding-left: 0;
}

.p-l-10 {
    padding-left: 10px;
}

.p-r-0 {
    padding-right: 0;
}

.m-b-50 {
    margin-bottom: 50px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.information-wrapper {
    background: white;
    border: 1px solid $light-blue;
}

via-typeahead[ng-reflect-disabled=true] {
    #typeahead {
        color: #999999 !important;
        background: #fbfbfb !important;
    }
}

.action-modal {
    width: 100%;
    .modal-dialog {
        width: 70%;
        max-width: 70%;
    }
}

.medium-action-modal {
    width: 100%;
    .modal-dialog {
        max-width: 800px;
    }
}

.action-depth-modal {
    width: 100%;
    .modal-dialog {
        -webkit-box-shadow: 0px 3px 5px 0px $modal-gray-shadow;
        -moz-box-shadow: 0px 3px 5px 0px $modal-gray-shadow;
        box-shadow: 0px 3px 5px 0px $modal-gray-shadow;
    }
    .modal-content {
        border: 0;
    }
    .modal-dialog {
        border-radius: 5px;
        border: 0;
        overflow: hidden;
    }
}

.modal-min-700 {
    .modal-dialog {
        min-width: 900px;
        table {
            width: 100%;
            td {
                vertical-align: top;
                padding: 5px;
            }
        }
    }
}

.w-50p {
    width: 50px;
    white-space: nowrap;
    padding: 0 5px;
}

.modal-footer {
    border-top: 0;
    display: block;
    margin-bottom: 4px;
    text-align: center;
}

.modal-logo {
    margin: 0px auto;
    margin-top: 20px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 420px;
        min-height: 300px;
    }
}

.modal-title {
    font-weight: bold;
}

.modal-body-text-p {
    padding: 0 45px;
    min-height: 65px;
}

.modal-action-btn {
    width: 160px;
    height: 40px;
}

.modal-header {
    border-bottom: 0;
    text-align: center;
}

.modal-body {
    padding-top: 0;
    p {
        margin-top: 24px;
    }
}

.light-backdrop {
    background-color: $modal-backgrop-gray;
}

.modal-content {
    border-radius: 5px;
    min-height: 300px;
}

.gm-control-active {
    padding: 0 !important;
}

.status {
    color: black;
    &.ACTIVE {
        color: $status-active;
        span {
            color: $status-active;
        }
    }
    &.DELETED {
        color: $status-deleted;
        span {
            color: $status-deleted;
        }
    }
    &.DONE {
        color: $status-active;
        span {
            color: $status-active;
        }
    }
}

.ACTIVE {
    span {
        color: $status-active;
    }
}

.DELETED {
    span {
        color: $status-deleted;
    }
}

.DONE {
    span {
        color: $status-active;
    }
}

.STATUS-false {
    span {
        color: $status-active !important;
    }
}

.STATUS-true {
    span {
        color: $status-deleted !important;
    }
}

.status-active {
    color: $status-active !important;
}

.status-expired {
    color: black !important;
}

.action-modal {
    overflow: scroll;
}

.popup {
    .fa-chevron-left {
        left: 0 !important;
        top: 150px !important;
    }
    .fa-chevron-right {
        right: 0 !important;
        top: 150px !important;
    }
}

.win-preview-popup {
    overflow-y: scroll;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: white;
    .win-preview-popup-detail {
        color: white;
        min-height: 450px;
        padding: 20px 0;
        position: relative;
        .win-preview-popup-description {
            font-size: 14px;
            .win-preview-popup-name {
                font-size: 18px;
                font-weight: bold;
            }
            .win-preview-popup-detail-text {
                font-size: 12px;
                margin-bottom: 10px;
            }
            .win-preview-popup-remaining {
                margin-top: 10px;
                margin-bottom: 45px;
                .remaining-wrapper {
                    font-size: 20px;
                    background: $my-wine-remaining-gray;
                    margin-top: 22px;
                    padding: 10px 10px 10px 10px;
                }
            }
        }
    }
}

.b-r-4 {
    border-radius: 4px;
}

.win-shadow {
    -webkit-box-shadow: 0 4px 30px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0 4px 30px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.win-list-view-wrapper {
    width: 100%;
    min-height: 400px;
    background: white; // padding-left: 10%;
    // padding-right: 10%;
    padding-top: 5px;
    -webkit-box-shadow: 200px -14px 42px 0px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 200px -14px 42px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 200px -14px 42px 0px rgba(0, 0, 0, 0.04);
    .win-list-items {
        padding-right: 15px;
        padding-left: 15px;
        .win-list-item {
            display: -webkit-inline-box;
            margin-bottom: 25px;
            width: 100%;
            text-align: left;
            cursor: pointer;
            .container-suggestion {
                border-radius: 4px;
            }
            .icon-shadow {
                -webkit-box-shadow: 0 4px 25px 0px rgba(0, 0, 0, 0.07);
                box-shadow: 0 4px 25px 0px rgba(0, 0, 0, 0.07);
            }
            .win-list-item-img {
                width: 40px;
                height: 40px;
                overflow: hidden;
                border-radius: 4px;
                margin-top: 3px;
                text-align: center;
                img {
                    width: auto;
                    height: 100%;
                }
            }
            .win-list-item-content {
                padding-left: 10px;
                .win-list-item-title {
                    font-size: 12px;
                    display: block;
                }
                .win-list-item-detail {
                    font-size: 10px;
                    display: block;
                    color: rgba(3, 15, 41, 0.4);
                }
            }
        }
    }
}

.logo-wrapper {
    border-radius: 4px;
    margin: 0 auto;
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin-top: 15px;
    margin-bottom: 15px;
    pointer-events: none;
    img {
        height: 100%;
    }
}

.org-name {
    font-size: 19px;
    font-weight: bold;
    color: $win-table-color-black;
    margin-top: 10px !important;
    margin-bottom: 0px;
}

.logo-container {
    img {
        width: 100%;
    }
}

.information-wrapper {
    background: white;
    border-radius: $win-control-boder-radius;
    margin-bottom: 35px;
    padding: 20px 20px 40px 20px;
    .row {
        flex-direction: row;
        .org-name {
            font-size: 19px;
            font-weight: bold;
            color: $win-table-color-black;
        }
        .org-code {
            color: $win-control-color;
            font-size: 15px;
        }
    }
}

via-dropdown {
    width: 100%;
}

.dropdown-item {
    height: 46px;
}

.dropdown-menu-items {
    max-height: 290px !important;
    margin-bottom: 200px;
}

.dropdown-divider {
    height: 0;
    margin: 0 0;
    overflow: hidden;
    border-top: 1px solid #ebedf8;
}

.carousel-item {
    min-height: 50vh;
    height: 80vh;
    max-height: 100vh;
    background: gray;
    text-align: center;
}

.dropdown-menu {
    padding: 0 0 0;
}

.carousel-indicators li {
    width: 15px;
    height: 15px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 50%;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

//LOAD MORE
.no-more-text {
    padding-left: 15px;
    margin-bottom: 30px;
    color: $disabled-text-gray;
}

//LOAD MORE SPINNER
.load-more-spinner {
    padding-left: 15px;
    padding-bottom: 15px;
    img {
        width: 20px;
    }
}

.gm-ui-hover-effect {
    padding: 0 !important;
}

#myRestaurantGallery {
    .carousel-control-next {
        height: 50px;
        position: absolute;
        border: 0;
        right: 0;
        bottom: 0;
        top: auto;
    }
    .carousel-control-prev {
        height: 50px;
        position: absolute;
        border: 0;
        left: 0;
        bottom: 0;
        top: auto;
    }
    .carousel-inner {
        margin-bottom: 50px;
        border: 10px solid white;
    }
    .modal-content {
        background: none;
    }
}

.gallery-modal {
    width: 100%;
    .modal-content {
        background: none;
        border: 0;
        outline: none;
    }
    .modal-dialog {
        border-radius: 0;
        border: 0;
        overflow: hidden;
    }
}

//ASPECT RATIO
.ratio-16-9 {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    background: white;
    .img-16-9 {
        height: 100%;
        position: absolute;
        top: 0;
    }
    .img-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}

.ratio-1-1 {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    .img-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}

.ngb-dp-arrow-btn {
    padding: 0 0.25rem !important;
}

.white-background {
    background: white;
}

.card-16-9-img-wrapper {
    position: relative;
    overflow: hidden;
    background: white;
    width: 100%;
    padding-top: 56.25%;
    img {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
}

#typeahead {
    .typeahead-input {
        font-size: 13px;
    }
}

.table-type {
    .typeahead-menu-item-input {
        position: relative;
    }
}

.no-indicators .carousel-indicators {
    display: none;
}

.STRING {
    span {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

input[type="radio"]:after {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type="radio"]:checked:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: $primary-color;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid #d1d3d1;
    margin-right: 10px;
}

.radio-label {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

#contract-wine-assign .typeahead-menu-items {
    max-height: 200px !important;
}

#view-claim-detail {
    .win-page-title {
        margin-bottom: 0;
    }
    .contract-wine-subtext {
        text-align: center;
        color: #bdbdbd;
    }
    .contract-wine-details {
        .img-icon {
            width: 15px;
            margin-right: 10px;
        }
    }
    .quantity-label {
        margin-top: 40px;
        margin-bottom: 10px;
    }
    .contract-wine-card-detail {
        background: white;
        padding: 10px;
        border-radius: 3px;
        border: 1px solid #ececec;
        margin-bottom: 10px;
        position: relative;
        min-height: 138px;
        .total-claimed {
            position: absolute;
            right: 10px;
            top: 5px;
            font-size: 13px;
            font-weight: bold;
        }
        .wine-logo {
            width: 60px;
            height: 60px;
            overflow: hidden;
            background: #f4f4f4;
            float: left;
            margin-right: 15px;
            img {
                height: 60px;
                width: auto;
            }
        }
        .wine-name.detail {
            margin-top: 0;
            padding-right: 20px;
        }
    }
    .blue-btn {
        color: #0800d1;
    }
}

.res-header-width {
    width: 50px;
}

.res-text {
    width: 300px;
    span {
        white-space: unset;
    }
}

// CLAIM
.my-wine-wrapper {
    width: 100%;
    max-height: 504px;
    overflow-y: scroll;
    .my-wine-logo {
        width: 115px;
        height: 115px;
        overflow: hidden;
        background: #f4f4f4;
        float: left;
        padding: 5px;
        text-align: center;
        margin-right: 15px;
        img {
            height: 100%;
            width: auto;
        }
    }
}

.gray-notice-txt {
    color: $disabled-text-gray;
}

.win-table-wrapper {
    z-index: 0;
}

.infor-text-sz {
    font-size: 11px;
}

#typeahead .typeahead-menu-item-selected {
    float: left;
}

.ngb-tp-meridian {
    button {
        border: 1px solid $primary-color;
        color: $primary-color;
        height: 46px;
        outline: none;
        &:hover {
            background-color: $primary-color !important;
            border-color: $primary-color !important;
            color: white !important;
        }
        &:active {
            background-color: $primary-color !important;
            border-color: $primary-color !important;
            color: white !important;
        }
        &:focus {
            box-shadow: none !important;
        }
    }
}

.wine-table {
    width: 100%;
    table {
        width: 100%;
        border: 1px solid whitesmoke;
        .header-tr {
            border-bottom: 1px solid whitesmoke;
            height: 40px;
            background: white;
            color: white;
            // th {
            //     padding: 0 10px;
            //     font-size: 13px;
            // }
            th {
                border-bottom: none;
                white-space: nowrap;
                font-size: 13px;
                text-transform: uppercase;
                color: #b4bac6;
                font-size: 12px;
                padding-left: 10px;
            }
        }
        .body-tr {
            border-bottom: 1px solid whitesmoke;
            height: 40px;
            .padding-cell {
                padding: 0 10px;
                font-size: 13px;
            }
            #typeahead {
                border: none;
            }
            input {
                border: none;
            }
        }
    }
}

.form-control:focus {
    border: 1px solid #EBEBEB;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.add-more-row {
    cursor: pointer;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    &:hover {
        background: #fbfbfb;
    }
    img {
        width: 30px;
    }
}

#typeahead {
    //max-width: 300px;
    display: block !important;
    .typeahead-input {
        z-index: 1 !important;
    }
    .typeahead-menu-items {
        z-index: 20 !important;
    }
}

via-typeahead {
    width: 100%;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: unset;
    color: black;
    border: none;
    outline: none;
}

.wine-table {
    width: 100%;
}

via-datepicker {
    width: 100%;
}

.footer {
    border-top: 0;
    display: block;
    margin-bottom: 4px;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.claimed-detail-icon {
    padding: 5px;
    text-align: center;
    img {
        height: 100% !important;
    }
}

.row-top-align {
    vertical-align: baseline;
}

.filter {
    .dropdown-menu-items {
        max-height: unset !important;
    }
    .typeahead-dropdown-wrapper {
        .dropdown-menu-items {
            max-height: 290px !important;
        }
    }
}

.custom-modal {
    .modal-header {
        display: block;
    }
    .modal-body {
        padding: 0 45px !important;
        margin-top: 24px;
    }
    .modal-title {
        width: 100% !important;
    }
    .modal-footer {
        margin-bottom: 15px;
    }
    .close {
        position: absolute;
        top: 25px;
        right: 20px;
        outline: none;
    }
    .footer {
        position: unset;
    }
}

.text-area-wrapper {
    max-height: 150px;
    overflow-y: scroll;
    padding-right: 10px;
    text-align: justify;
    height: 100%;
    white-space: pre-line;
}

.text-area-wrapper-90 {
    max-height: 90px;
    overflow-y: scroll;
    padding-right: 10px;
    text-align: justify;
    height: 100%;
    white-space: pre-line;
}

.text-area-wrapper-342 {
    max-height: 342px;
    overflow-y: scroll;
    padding-right: 10px;
    text-align: justify;
    height: 100%;
    white-space: pre-line;
}

.text-area-wrapper-140 {
    max-height: 140px;
    overflow-y: scroll;
    padding-right: 10px;
    text-align: justify;
    height: 100%;
    white-space: pre-line;
}

.text-area-wrapper-240 {
    max-height: 240px;
    overflow-y: scroll;
    padding-right: 10px;
    text-align: justify;
    height: 100%;
    white-space: pre-line;
}

via-upload-file {
    width: 100%;
}

.font-16 {
    font-size: 16px;
}

.p-0 {
    padding: 0;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-r-20 {
    padding-right: 20 !important;
}

.p-l-0 {
    padding-left: 0 !important;
}

.fix-scroll {
    position: sticky;
    top: 90px;
    margin-top: 17px;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d82;
}

.ntn-priority {
    background: none;
    border: none;
    color: #ffbc00;
    font-weight: bold;
    cursor: unset;
}

#win-datepicker {
    border: none !important;
}

.white-bg {
    outline: none;
    .carousel-item {
        background: white;
        height: 100vh;
        cursor: pointer;
    }
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='cccccc' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
    }
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='cccccc' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
    }
    ngb-carousel:focus {
        outline: none !important;
    }
    .carousel-indicators .active {
        width: 5px!important;
        height: 5px!important;
        background-color: #636363;
    }
    .carousel-indicators li {
        width: 5px !important;
        height: 5px !important;
        background-color: rgb(182, 182, 182);
        opacity: 0.5;
    }
    .carousel-indicators {
        position: fixed;
        bottom: 0;
        margin-left: 0;
        margin-right: 0;
    }
}

.ngb-dp-day {
    .btn-light {
        background: none !important;
    }
    .bg-primary {
        color: white !important;
        background-color: #007bff !important;
    }
    .btn-light.focus,
    .btn-light:focus,
    .btn-light:hover {
        color: #212529;
        background-color: #e2e6ea !important;
        border-color: #dae0e5;
    }
}

.carousel-caption {
    background: #80808082;
}

.ngb-dp-day .bg-primary {
    color: #fff!important;
    background-color: #007bff !important;
}

.ngb-dp-today {
    .btn-light {
        background: #f1f1f1 !important;
    }
}

.win-table-container{
    margin-bottom: 100px;
    padding-bottom: 10px;
}