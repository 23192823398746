button, input, .btn:focus{
    outline: none;
    box-shadow: none;
}

button:focus {
    outline: none !important;
}

ul.none {
    list-style-type: none;
    margin: 0;
    padding: 10px;
}
label {
    margin-bottom: initial;
}


.member-btn {
    min-width: 160px;
}

.btn {
    padding: 10px 25px !important;
    font-size: 12px;
    height: 40px;
    border-radius: 3px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    cursor: pointer;
}

.btn-light {
    background: $light-gray !important;
    border-color: $light-gray !important;
    color: $light-gray-btn-color !important;
    &:hover {
        background-color: $light-gray-hover !important;
        border-color: $light-gray-hover !important;
    }
}

.btn-primary {
    background: $primary-color !important;
    border-color: $primary-color !important;
    &:hover {
        background-color: $primary-color-lighter !important;
        border-color: $primary-color-lighter !important;
    }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active {
    background-color: $primary-color-lighter;
    border-color: $primary-color-lighter;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem $primary-color-lighter;
}

.btn-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem $primary-color-lighter;
}

.btn-primary:focus,
.btn-primary.focus {
    box-shadow: 0 0 0 0.2rem $primary-color-lighter;
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-light.disabled,
.btn-light:disabled {
    background-color: $disabled-gray;
    border-color: $disabled-gray;
    color: $disabled-text-gray;
    box-shadow: none;
    cursor: default;
}

.btn-primary:focus,
.btn-primary.focus {
    box-shadow: none !important;
    ;
}

.btn-dark {
    background: #0B0C0F !important;
    border-color: #0B0C0F !important;
    height: 35px;
    border-radius: 10px;
    color: #B48C40;
    &.btn-sm{
        padding: 8px 25px !important;
    }
    &:hover {
        background-color: #16181f !important;
        border-color: #16181f !important;
    }
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active {
    background-color: #16181f;
    border-color: #16181f;
}

.btn-dark:not(:disabled):not(.disabled).active {
    color: #B48C40;
}

.btn-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem #16181f;
}

.btn-dark:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem #16181f;
}

.btn-dark:focus,
.btn-dark.focus {
    box-shadow: 0 0 0 0.2rem #16181f;
}

.btn-light.disabled,
.btn-light:disabled,
.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #eaeaea !important;
    border-color: #eaeaea !important;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    background-color: #007bff !important;
    color: #fff !important;
}

.form-group {
    .bold-txt {
        font-weight: bold;
    }
    label {
        font-size: 14px;
        color: $text-gray-color;
    }
    .display-txt {
        font-size: 13px;
        white-space: pre-line;
    }
    .emailHelp {
        font-size: 11px;
        color: $text-gray-color;
    }
}

.form-control {
    min-height: 46px;
    border-radius: 3px;
    border: 1px solid $input-border-gray-color;
    font-size: 13px;
    &:focus {
        border: 2px solid $primary-color-lighter;
        box-shadow: none;
    }
    &.ng-invalid.ng-touched {
        border: 2px solid $input-error;
    }
}

.form-control[readonly] {
    background: #f8f8f8;
}

.nav-tabs {
    background: transparent;
    border: 0;
    .nav-item {
        padding: 10px 30px 10px 0;
        font-size: 12px;
        font-weight: bold;
        .nav-link {
            border-radius: 0;
            background: transparent;
            border: 0;
            padding: 0;
            color: rgba($win-table-paging-color, 0.5);
            text-transform: uppercase;
            &.active {
                color: $win-table-paging-color;
            }
        }
    }
}

.dropdown-toggle {
    &::after{
        display: none;
    }
}