@import "./variables.scss";
.toast-container {
    width: 100%;
    position: fixed;
    z-index: 99999;
    .ngx-toastr {
        padding: 0;
        width: 400px;
        background-image: none;
    }
    .toast-error {
        background-color: #F05C6B;
    }
    .toast-success {
        background-color: #79C753;
    }
}
.win-table-container{
    ul.pagination {
        .page-item {
            color: $paging-button-color !important;
            margin-right: 3px;
            &.active {
                color: $paging-button-color-active;
                .page-link {
                    color: white !important;
                    background: $paging-button-color-active !important;
                    border-color: $paging-button-color-active !important;
                    z-index: inherit !important;
                }
            }
            &.disabled {
                .page-link {
                    border: 0 !important;
                }
            }
            .page-link {
                color: $paging-button-color !important;
                border-color: $paging-button-color !important;
                border-radius: $win-control-boder-radius !important;
            }
        }
    }
}
