@import "../theme/v1/scss/colors.scss";

.collapsed {
    .content-page {
        margin-left: 50px !important;
        -moz-transition: margin .5s;
        -ms-transition: margin .5s;
        -o-transition: margin .5s;
        -webkit-transition: margin .5s;
        transition: margin .5s;
    }
    #sd-navbar {
        width: 50px !important;
        -moz-transition: width .5s;
        -ms-transition: width .5s;
        -o-transition: width .5s;
        -webkit-transition: width .5s;
        transition: width .5s;
        .logo {
            padding-right: 0px;
            padding-left: 0px;
            img {
                width: 45px !important;
                -moz-transition: width .5s;
                -ms-transition: width .5s;
                -o-transition: width .5s;
                -webkit-transition: width .5s;
                transition: width .5s;
            }
        }
    }
}


/* Box-sizing border-box */

*,
*:before,
*:after {
    box-sizing: border-box;
}


/* Set up a default font and some padding to provide breathing room */

p {
    font-weight: 400;
    letter-spacing: 0.01em;
    line-height: 20px;
    margin-bottom: 1em;
    margin-top: 1em;
}

ul {
    margin: 10px 0 0;
    padding: 0 0 0 20px;
}

li {
    font-weight: 400;
    margin-top: 4px;
}

a {
    cursor: pointer;
}

.via-pointer {
    cursor: pointer;
}

.text-divider {
  display: flex;
  align-items: center;
  --text-divider-gap: 1rem;
  width: 100%;
  white-space: nowrap;
  font-size: 16px;
}
.text-divider::before,
.text-divider::after {
  content: '';
  height: 1px;
  background-color: #e1e2e1;
/*   flex-grow: 1; */
}
.text-divider::before {
  margin-right: var(--text-divider-gap);
  width: 10%
}
.text-divider::after {
  margin-left: var(--text-divider-gap);
  width: 90%
}

.dialog-container{
  .dialog-header{
    display: flex;
  }

  .header-txt{
    font-size: 28px;
    flex: 1;
    text-align: center;
  }
  
  .item-row{
    display: flex;
    padding: 10px 0;
  }
  .item-left {
    margin-right: 50px;
  }
  .item-right {
    flex: 1;
    text-align: right;
  }
}
.clusterWrapper {
  div{
    span{
      color: $accent-color-darker;
      font-size: 14px;
    }
  }
}

.fullscreen-mat-dialog{
  min-width: 95vw;
  min-height: 90vh;
  max-width: 95vw;
  .mat-dialog-container{
    padding: 10px 24px;
  }
  .mat-dialog-content{
    padding: 0;
    max-height: 90vh;
    max-width: 90vw;
    margin: 0;
    .modal-body{
      padding: 0;
      overflow-x: hidden;
    }
  }
}

.register-support-wrapper{
  .map-wrapper{
    .fullscreen-map-wrapper{
      display: none;
    }
    .close-fullscreen-wrapper{
      display: none;
    }
  }
  @media screen and (max-width: 767px) and (max-width: 991px){
    .map-wrapper{
      via-map{  
        .google-maps{
          width: 100%;
          height: 300px;
        }
        .my-location-btn{
          display: block;
        }
      }
    }
  }
  @media screen and (max-width: 641px){
    .map-wrapper{
      position: relative;
      .close-fullscreen-wrapper{
        display: none;
      }
      .gm-fullscreen-control{
        display: none;
      }
      .map-view-fullscreen{
        width: 100vw;
        height: 100vh;
        background-color: white;
        .google-maps{
          width: 100vw;
          height: 100vh;
        }
        .map-search-container{
          margin-bottom: 0;
          >div{
            margin-bottom: 0;
          }
        }
        .close-fullscreen-wrapper{
          display: block;
          width: 40px;
          height: 40px;
          position: absolute;
          z-index: 9999;
          background: white;
          top: 55px;
        }
      }
      via-map{
        .google-maps{
          width: 30px;
          height: 30px;
        }
        .my-location-btn{
          display: none;
        }
      }
      .fullscreen-map-wrapper{
        display: block;
        position: absolute;
        height: 40px;
        bottom: 0;
        left: 0;
        background-color: white;
        cursor: pointer;
      }
    }
  }
}

.support-action-wrapper{
  mat-label{
      font-size: 14px;
      color: #58585A;
  }
}