/* Material Theme Colors */
$primary-color: #fdc428;
$primary-color-lighter: #fcd25f;
$primary-color-darker: #d1a21f;
$accent-color:#008c91;
$accent-color-lighter:#1aaaa9;
$accent-color-darker:#007b7a;
$warn-color:#ea9e18;
$warn-color-lighter:#e6ac49;
$warn-color-darker:#c48315;
$dark-text-color: #000000;
$light-text-color: #ffffff;
$light-background-color: #fafafa;
$dark-background-color: #2c2c2c;

/* Application Colors */
$light-gray: rgba(247, 248, 249, 1);
$light-gray-hover: #efefef;
$light-gray-btn-color:rgba(90, 90, 90, 1);
$disabled-gray: rgba(234, 234, 234, 1);
$disabled-text-gray: rgba(170, 178, 192, 1);
$gray: #e8e8e8;
$modal-gray-shadow: rgba(0, 0, 0, 0.16);
$modal-backgrop-gray: rgba(#000000, 0.7);
$dark-gray: gray;
$darker-gray: #5A5A5A;
$light-blue: #EBEDF8;
$dark-blue: #8A96A0;
$transparent-blue: rgba(37, 38, 94, 0.01);
$error-pink: #F05C6B;
$success-green: #79C753;
// SWITCH 
$switch-blue: #0800D1;
$switch-gray: rgba(239, 239, 239, 1);
$switch-border: rgba(0, 0, 0, 0.1);
$switch-disabled-handle :#f3f3f3;
$switch-box-shadow: rgba(0, 0, 0, 0.3);
// INPUT
$input-focus: rgba(0, 119, 255, 1);
$input-error: rgba(231, 56, 125, 1);

/* Box shadows */

// HEADER
$username: #637280;
$z-depth-1: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
0 1px 5px 0 rgba(0, 0, 0, 0.12),
0 3px 1px -2px rgba(0, 0, 0, 0.2);
$boder-color: #C4CDD9;
$label-color-gray: #999999;

/* Form control */

$text-gray-color: #58585A;
$input-border-gray-color: #EBEBEB;
$gray: #F7F8F9;
$boder-color: #C4CDD9;
$label-color-gray: #999999;
$check_color: #BCBEC0;
$check_selected_color: #2A7AF6;
$win-control-border: #EBEBEB;
$win-control-color: #999999;
$win-table-color: #B4BAC6;
$win-table-color-black: #354052;
$win-table-filter-boder-color: #F0F0F0;
$win-table-paging-color: #AAB2C0;
$status-new: #79C753;
$status-active: #0077FF;
$status-deleted: #9A0000;
$paging-button-color: #C7CDD9;
$paging-button-color-active: #0800D1;
$page-background: #FAFBFC;
// My Wine
$my-wine-gray: #95989A;
$my-wine-text-gray: rgba(3, 15, 41, 0.4);
$my-wine-shadow-gray: rgba(0, 0, 0, 0.16);
$my-wine-remaining-gray: rgba(3, 15, 41, 0.1);
//user
$user-gray:rgba(235, 235, 235, 1);