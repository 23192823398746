.switch-wraper {
    height: 40px;
    display: flex;
    .title {
        margin: 0 10px 0 0;
        // text-transform: uppercase;
        color: $win-table-color;
    }
}

input[type="checkbox"].ios8-switch {
    position: absolute;
    margin: 8px 0 0 16px;
    visibility: hidden;
}

input[type="checkbox"].ios8-switch+label {
    position: relative;
    padding: 5px 0 0 50px;
    line-height: 2.0em;
}

input[type="checkbox"].ios8-switch+label:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 40px;
    /* x*5 */
    height: 24px;
    /* x*3 */
    border-radius: 16px;
    /* x*2 */
    background: $switch-gray;
    border: 1px solid $switch-border;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

input[type="checkbox"].ios8-switch+label:after {
    content: "";
    position: absolute;
    display: block;
    left: 0px;
    top: 0px;
    width: 24px;
    /* x*3 */
    height: 24px;
    /* x*3 */
    border-radius: 16px;
    /* x*2 */
    background: white;
    border: 1px solid $switch-border;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

input[type="checkbox"].ios8-switch+label:hover:after {
    box-shadow: 0 0 5px $switch-box-shadow;
}

input[type="checkbox"].ios8-switch:checked+label:after {
    margin-left: 16px;
}

input[type="checkbox"].ios8-switch:checked+label:before {
    background: $switch-blue;
}

input[type="checkbox"].ios8-switch:disabled+label:before {
    background: $disabled-gray;
}

input[type="checkbox"].ios8-switch:disabled+label:after {
    background: $switch-disabled-handle;
}

/* SMALL */

input[type="checkbox"].ios8-switch-sm {
    margin: 5px 0 0 10px;
}

input[type="checkbox"].ios8-switch-sm+label {
    position: relative;
    padding: 0 0 0 32px;
    line-height: 1.3em;
}

input[type="checkbox"].ios8-switch-sm+label:before {
    width: 25px;
    /* x*5 */
    height: 15px;
    /* x*3 */
    border-radius: 10px;
    /* x*2 */
}

input[type="checkbox"].ios8-switch-sm+label:after {
    width: 15px;
    /* x*3 */
    height: 15px;
    /* x*3 */
    border-radius: 10px;
    /* x*2 */
}

input[type="checkbox"].ios8-switch-sm+label:hover:after {
    box-shadow: 0 0 3px $switch-box-shadow;
}

input[type="checkbox"].ios8-switch-sm:checked+label:after {
    margin-left: 10px;
    /* x*2 */
}

/* LARGE */

input[type="checkbox"].ios8-switch-lg {
    margin: 10px 0 0 20px;
    &:disabled {
        background: #ccc;
    }
}

input[type="checkbox"].ios8-switch-lg+label {
    position: relative;
    padding: 7px 0 0 60px;
    line-height: 20px;
}

input[type="checkbox"].ios8-switch-lg+label:before {
    width: 50px;
    /* x*5 */
    height: 30px;
    /* x*3 */
    border-radius: 20px;
    /* x*2 */
}

input[type="checkbox"].ios8-switch-lg+label:after {
    width: 30px;
    /* x*3 */
    height: 30px;
    /* x*3 */
    border-radius: 20px;
    /* x*2 */
}

input[type="checkbox"].ios8-switch-lg+label:hover:after {
    box-shadow: 0 0 8px $switch-box-shadow;
}

input[type="checkbox"].ios8-switch-lg:checked+label:after {
    margin-left: 20px;
    /* x*2 */
}