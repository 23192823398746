@import 'colors';
@import 'variables';
@import 'mixins';
@import 'fonts';
@import "switch";
@import 'common';
@import 'bootstrap.override';
@import 'material.override';

#main {
    &.container {
        width: 100%;
        padding: 0;
    }
    .row {
        padding: 0;
        margin: 0;
    }
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9 {
        padding: 0;
    }
    .card {
        margin-bottom: 15px;
        .card-header {
            background: white;
            padding: 10px 5px;
        }
        .card-header-icon,
        .card-header-title {
            cursor: pointer;
            font-weight: bold;
        }
        .card-header-icon {
            text-align: right;
        }
        .card-block {
            min-height: 250px;
            max-height: 400px;
            overflow: auto;
        }
        .card-block-content,
        .card-block-footer {
            padding: 5px;
            &.query-builder-content {
                min-height: 200px;
            }
        }
        .card-block-footer {
            .right {
                text-align: right;
            }
        }
    }
    .treeview {
        float: left;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        label {
            margin-bottom: 0;
        }
    }
    .treeview.hover input~label:before,
    .treeview:hover input~label:before {
        opacity: 1.0;
        -webkit-transition-duration: 0.5s;
        -moz-transition-duration: 0.5s;
        -ms-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
    }
    .treeview ul {
        -webkit-transition-duration: 1s;
        -moz-transition-duration: 1s;
        -ms-transition-duration: 1s;
        -o-transition-duration: 1s;
        transition-duration: 1s;
        list-style: none;
        padding-top: 0;
        margin-top: 0;
    }
    .treeview ul li span {
        -webkit-transition-property: color;
        -moz-transition-property: color;
        -ms-transition-property: color;
        -o-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 1s;
        -moz-transition-duration: 1s;
        -ms-transition-duration: 1s;
        -o-transition-duration: 1s;
        transition-duration: 1s;
    }
    .treeview ul li span:hover {
        color: gray;
        -webkit-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        -ms-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s;
    }
    .treeview input {
        display: none;
    }
    .treeview input:checked~ul {
        display: none;
    }
    .treeview input~label {
        cursor: pointer;
    }
    .treeview input~label:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        margin-left: -1em;
        margin-top: 0.4em;
        border-width: 4px;
        border-style: solid;
        border-top-color: transparent;
        border-right-color: rgba(255, 255, 255, 0.5);
        border-bottom-color: rgba(255, 255, 255, 0.5);
        border-left-color: transparent;
        opacity: 0.0;
        -webkit-transition-property: opacity;
        -moz-transition-property: opacity;
        -ms-transition-property: opacity;
        -o-transition-property: opacity;
        transition-property: opacity;
        -webkit-transition-duration: 1s;
        -moz-transition-duration: 1s;
        -ms-transition-duration: 1s;
        -o-transition-duration: 1s;
        transition-duration: 1s;
    }
    .treeview input:checked~label:before {
        margin-left: -0.8em;
        border-width: 5px;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: rgba(255, 255, 255, 0.5);
    }
    #mainCanvas,
    .canvas {
        overflow: visible !important;
        margin-right: 25px;
    }
}